<template>
  <div>
    <pageTitle :title="$t('supplementaryInfo')"></pageTitle>
    <div v-if="type === 'view'" class="box" v-loading="loading">
      <el-form
        :label-width="LOCALE === 'en' ? '150px' : '100px'"
        :model="viewForm"
        :hide-required-asterisk="true"
        label-position="left"
      >
        <el-form-item
          :label="$t('adeptBusiness') + $t('colon')"
          prop="business"
        >
          <div class="viewBusslist">
            <div
              v-for="(tag, index) in viewForm.business_classification"
              :key="index"
              class="viewBussItem"
            >
              {{ tag | bussinessFormat(LOCALE) }}
            </div>
            <div
              v-for="(tag, index) in viewForm.user_tags"
              :key="index"
              class="viewBussItem"
            >
              {{ tag.desc_en | priorFormat(tag.desc_zh, LOCALE) }}
            </div>
            <div
              v-if="
                viewForm.business_classification.length === 0 &&
                  viewForm.user_tags.length === 0
              "
            >
              -
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('BussinessMajor') + $t('colon')" prop="line">
          <div class="viewBusslist">
            <div
              v-for="(tag, index) in viewForm.line"
              :key="index"
              class="viewBussItem"
            >
              {{ tag | shippingFormat(LOCALE) }}
            </div>
            <div v-if="viewForm.line.length === 0">-</div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('Personal_profile') + $t('colon')"
          prop="brief"
        >
          <div class="brief" style="width:1000px">
            {{ viewForm.brief | textFormat }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('videoInfo') + $t('colon')" prop="video">
          <div class="videoList" v-if="viewForm.videos.length > 0">
            <div
              class="item"
              v-for="(item, index) in viewForm.videos"
              :key="index"
            >
              <div class="img pointer img-wrap">
                <el-image
                  :src="item.cover_url"
                  fit="scale-down"
                  class="item"
                ></el-image>
                <div class="mark">
                  <i
                    class="el-icon-video-play cursor"
                    @click="handleContent(item)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="viewForm.videos.length === 0 && !loading"
            style="margin-top: 12px"
          >
            -
          </div>
        </el-form-item>
      </el-form>
      <!-- v-if="inspectButton('mem:cai:mdi')" -->
      <el-button
        class="btn"
        type="primary"
        size="small"
        style="min-width:80px;margin-left:2px"
        @click="handleEditBtn"
        >{{ $t("modify") }}</el-button
      >
    </div>
    <div v-if="type === 'edit'" class="box">
      <el-form
        :label-width="LOCALE === 'en' ? '150px' : '100px'"
        :model="editForm"
        ref="form"
        :rules="formRule"
        :hide-required-asterisk="true"
        label-position="left"
      >
        <el-form-item
          class="clearfix"
          :label="$t('adeptBusiness') + $t('colon')"
          prop="business"
        >
          <div class="fl tagDiv businessTagWrap">
            <div class="tagDivLeft">
              <div
                :title="tag | bussinessFormat(LOCALE)"
                v-for="(tag, index) in editForm.business_classification"
                :key="index"
                class="editTagclass"
              >
                <div class="editBabel textOverflow">
                  {{ tag | bussinessFormat(LOCALE) }}
                </div>
                <div class="point color000000" @click="delBussiness(index)">
                  <i class="el-icon-close"></i>
                </div>
              </div>
              <div
                :title="tag.desc_en | priorFormat(tag.desc_zh, LOCALE)"
                v-for="(tag, index) in editForm.user_tags"
                :key="index"
                class="editTagclass"
              >
                <div class="editBabel textOverflow">
                  {{ tag.desc_en | priorFormat(tag.desc_zh, LOCALE) }}
                </div>
                <div class="point color000000" @click="delTag(index)">
                  <i class="el-icon-close"></i>
                </div>
              </div>
              <div
                v-if="editForm.business_classification.length === 0&&editForm.user_tags.length === 0"
                class="placeholderHint"
              >
              {{
                LOCALE ==='en'?`please choose service, at most ${businessMaxNumber}`:`请选择业务，不超过${businessMaxNumber}个`
              }}
              </div>
            </div>

            <div class="AddBtn tagDivRight">
              <span class="point" @click="addBussiness">
                {{ $t("add") }} <i class="el-icon-circle-plus-outline"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('BussinessMajor') + $t('colon')" prop="line">
          <div class="fl tagDiv">
            <div
              :title="tag | shippingFormat(LOCALE)"
              v-for="(tag, index) in editForm.line"
              :key="index"
              class="editTagclass"
            >
              <div class="editBabel textOverflow">
                {{ tag | shippingFormat(LOCALE) }}
              </div>
              <div class="point color000000" @click="delLine(index)">
                <i class="el-icon-close pointer"></i>
              </div>
            </div>
            <div v-if="editForm.line.length === 0" class="placeholderHint">
              {{ $t("replenishInfoText2") }}
            </div>
            <div class="AddBtn">
              <span class="point" @click="addLine">
                {{ $t("add") }} <i class="el-icon-circle-plus-outline"></i>
              </span>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('Personal_profile') + $t('colon')"
          prop="brief"
        >
          <el-input
            :placeholder="$t('replenishInfoText3')"
            style="width: 500px"
            type="textarea"
            v-model="editForm.brief"
            resize="none"
            show-word-limit
            :autosize="{ minRows: 4 }"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('videoInfo') + $t('colon')" prop="video">
          <div class="img-wrap clearfix videoList vidoeBox">
            <div class="upload fl" v-loading="uoloadVideoLoading">
              <fileUpload
                ref="uploadComponents"
                :isSingleVideo="true"
                :companyId="USER_INFO.company_id"
                :isTranscode="false"
                :limit="3"
                :isDisabled="editForm.videos.length >= 3"
                :accept="fileUploadOptions.accept"
                @VODcallbackComplete="VODcallbackComplete"
                @startUpdateVideo="startUpdateVideo"
              >
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  v-if="editForm.videos.length !== 3"
                ></i>
              </fileUpload>
            </div>
            <div
              class="item fl"
              v-for="(item, index) in editForm.videos"
              :key="index"
            >
              <div class="img pointer img-wrap">
                <el-image
                  :src="item.cover_url"
                  fit="scale-down"
                  class="item"
                ></el-image>
                <div class="mark">
                  <i
                    class="el-icon-video-play cursor"
                    @click="handleContent(item)"
                  ></i>
                </div>
              </div>
              <div class="delVideos" @click="delVideos(item, index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
          <div class="vide0Hint">{{ $t("replenishInfoText4") }}</div>
        </el-form-item>
      </el-form>
      <el-button
        :loading="savaBtnLoading"
        class="btn"
        type="primary"
        size="small"
        style="min-width:80px;margin-left:2px"
        @click="handleSaveBtn"
        >{{ $t("save") }}</el-button
      >
      <el-button
        size="small"
        style="min-width:80px;margin-left:10px"
        class="btn"
        @click="handleCancel"
        >{{ $t("cancel") }}</el-button
      >
    </div>
    <el-dialog
      :title="$t('adeptBusiness')"
      :visible.sync="dialogVisibleBus"
      width="900px"
      :close-on-click-modal="false"
    >
      <!-- <BussinessClassification
        :isSelect="isSelect"
        :number="5"
        :isCenter="false"
        :type="'1'"
        :classification="businessAllList"
        v-if="dialogVisibleBus"
        @saveClass="saveClass"
        @closeClass="closeBussClass"
      ></BussinessClassification> -->
      <component
        :is="isThatBusiness"
        :isSelect="isSelect"
        :number="businessMaxNumber"
        :isCenter="false"
        :type="'1'"
        :classification="businessAllList"
        v-if="dialogVisibleBus"
        @closeClass="closeBussClass"
        @saveClass="saveClass"
      >
      </component>
    </el-dialog>
    <el-dialog
      :title="$t('BussinessMajor')"
      :visible.sync="dialogVisibleLine"
      width="900px"
      :close-on-click-modal="false"
    >
      <majorLine
        v-if="dialogVisibleLine"
        :number="5"
        @cancelLine="dialogVisibleLine = false"
        @saveLine="saveLine"
        :majorLine="shippingLineAll"
        :isSelect="lineIsSelect"
        @closeClass="closeLineClass"
      ></majorLine>
    </el-dialog>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import { getBusinessObject, priorFormat } from "@/basePlugins/filters";
import BussinessClassification from "@/baseComponents/BussinessClassification";
import { businessList } from "@/baseUtils/enumValue";
import { shippingLine } from "~bac/utils/enumValue";
import majorLine from "~bac/components/company/business/majorLine";
import videoPlay from "@/baseComponents/videoPlay";
import fileUpload from "~/baseComponents/fileUpload";
import axpCompanyBusiness from "~bac/components/business/companyBusiness";

export default {
  components: {
    pageTitle,
    BussinessClassification,
    majorLine,
    videoPlay,
    fileUpload,
    axpCompanyBusiness,
  },
  data() {
    return {
      type: "view",
      loading: false,
      viewForm: {
        //  参数，和后台交互
        business_classification: [], // 优势
        line: [], //航线
        brief: "",
        videos: [],
        user_tags: [],
      }, //  显示
      editForm: {
        //  参数，和后台交互
        business_classification: [], // 优势
        line: [], //航线
        brief: "",
        videos: [],
        user_tags: [],
      }, // 修改
      formRule: {},
      businessAllList: {}, // 全部的优势，是分开的四个数组
      dialogVisibleBus: false,
      isSelect: [], // 已经有的优势
      businessList, //   优势总数组
      shippingLineAll: shippingLine, // 航线总数组
      lineIsSelect: [], //  已经有的航线
      dialogVisibleLine: false,
      uoloadVideoLoading: false,
      savaBtnLoading: false,
      fileUploadOptions: {
        accept: ".mp4, .avi, .rmvb, .ts, .flv, .wmv, video/*",
      },
    };
  },
  computed: {
    isThatBusiness() {
      return this.PJSource === 21
        ? "axpCompanyBusiness"
        : "BussinessClassification";
    },
    businessMaxNumber() {
      return this.PJSource === 21 ? 10 : 5;
    },
  },
  mounted() {
    this.getbusinessAll();
    this.getDataList();
  },
  methods: {
    getbusinessAll() {
      this.businessAllList = getBusinessObject([], true);
    },
    async getDataList() {
      this.loading = true;
      let params = { user_id: this.USER_INFO.id };
      // // 请求数据，获取到form.business_classification之后，得到isSelect
      let result = await this.$store.dispatch(
        "baseConsole/getCollectionDetail",
        params
      );
      this.loading = false;
      if (result.success) {
        console.log(result);
        this.viewForm.business_classification =
          result.data.business_classification;
        this.viewForm.user_tags =
          result.data.business_classification_info.user_tags;
        console.log(this.viewForm.user_tags);
        this.viewForm.line = result.data.shipping_line;
        this.viewForm.brief = result.data.user_profile;
        this.viewForm.videos = result.data.video_list;
      }
    },
    //处理优势业务数据
    handleBussiness() {
      let isSelect = {
        Convertional: [],
        "Dangerous Cargo": [],
        "Other convertional": [],
        "Particular Cargo": [],
        Services: [],
      };
      let arr = [];
      this.businessList.forEach((item, index) => {
        this.editForm.business_classification.forEach((ee, vv) => {
          if (ee == item.id) {
            arr.push(item);
          }
        });
      });
      arr.forEach((item, index) => {
        if (item.name_en === "Convertional") {
          isSelect["Convertional"].push(item);
        }
        if (item.name_en === "Dangerous Cargo") {
          isSelect["Dangerous Cargo"].push(item);
        }
        if (item.name_en === "Other convertional") {
          isSelect["Other convertional"].push(item);
        }
        if (item.name_en === "Particular Cargo") {
          isSelect["Particular Cargo"].push(item);
        }
        if (item.name_en === "Services") {
          isSelect["Services"].push(item);
        }
      });
      isSelect.user_tags=this.editForm.user_tags
      this.isSelect = isSelect;
    },
    saveClass(e, tag) {
      console.log(e);
      this.editForm.business_classification = e;
      this.editForm.user_tags = tag;

      this.dialogVisibleBus = false;
    },
    closeBussClass() {
      this.dialogVisibleBus = false;
    },
    addBussiness() {
      this.handleBussiness();
      this.dialogVisibleBus = true;
    },
    addLine() {
      this.dialogVisibleLine = true;
    },
    delBussiness(index) {
      this.editForm.business_classification.splice(index, 1);
    },
    delTag(index) {
      this.editForm.user_tags.splice(index, 1);
    },

    // 航线
    saveLine(e) {
      this.editForm.line = e;
      this.handleLineIsSelect();
      this.dialogVisibleLine = false;
    },
    closeLineClass() {
      this.dialogVisibleLine = false;
    },
    handleLineIsSelect() {
      let isSelect = [];
      this.shippingLineAll.forEach((item) => {
        this.editForm.line.forEach((vv) => {
          if (vv == item.id) {
            isSelect.push(item);
          }
        });
      });
      this.lineIsSelect = isSelect;
    },
    delLine(index) {
      this.editForm.line.splice(index, 1);
      this.handleLineIsSelect();
    },
    // 视频
    VODcallbackComplete(result) {
      this.uoloadVideoLoading = false;
      this.editForm.videos.push(result);
    },
    startUpdateVideo() {
      this.uoloadVideoLoading = true;
    },
    handleContent(item) {
      this.$refs.vidoePlay.startPlay(item.aliyun_id);
    },
    delVideos(item, index) {
      this.$refs.uploadComponents.deleteOssItem(item.name);
      this.editForm.videos.splice(index, 1);
    },
    handleEditBtn() {
      this.type = "edit";
      this.editForm = JSON.parse(JSON.stringify(this.viewForm));
      this.handleBussiness();
      this.handleLineIsSelect();
    },
    async handleSaveBtn() {
      this.savaBtnLoading = true;
      let business_classification = this.editForm.business_classification.join(
        ","
      );
      let company_shipping_line = this.editForm.line.join(",");
      let videos = this.editForm.videos.map((item) => item.id);
      let video_ids = videos.join(",");
      let profile = this.editForm.brief;
      let params = {
        company_id: this.USER_INFO.id,
        kind: 2,
        business_classification,
        company_shipping_line,
        profile,
        video_ids,
      };
      params.user_tags = JSON.stringify(this.editForm.user_tags);
      params._NOCLEAR = true;
      let data = await this.$store.dispatch(
        "baseConsole/company_savaBussiness",
        params
      );
      this.savaBtnLoading = false;
      if (data.success) {
        this.$message.success(this.$t("changeSuccess"));
        this.viewForm = JSON.parse(JSON.stringify(this.editForm));
        // this.getDataList()
        this.type = "view";
      }
    },
    handleCancel() {
      this.type = "view";
    },
  },
};
</script>
<style scoped lang="less">
.box {
  padding-left: 30px;
}
.viewBusslist {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 14px;
  .viewBussItem {
    display: inline-block;
    color: #39b2a8;
    margin-right: 20px;
    margin-bottom: 5px;
  }
}
.brief {
  margin-top: 14px;
}
.videoList {
  margin-top: 24px;
  display: flex;
  height: 180px;
  min-width: 400px;
  position: relative;
  .item {
    width: 288px;
    margin-right: 20px;
    .img {
      width: 288px;
      height: 162px;
      overflow: hidden;
      border-radius: 10px 10px 0px 0px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .img-wrap {
    position: relative;
    .mark {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 162px;
      line-height: 162px;
      text-align: center;
      .el-icon-video-play {
        font-size: 30px;
        color: #aaa;
      }
    }
    .mark:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
      color: #fff;
    }
  }
}
.tagDiv {
  border: 1px solid #e4e7ed;
  padding: 4px 10px;
  overflow: hidden;
  display: flex;
  display: -moz-box; /* Firefox */
  align-items: center;
  width: 500px;
  margin-right: 4px;
  margin-top: 2px;
  height: 28px;
  position: relative;
  .editTagclass {
    margin-right: 8px;
    margin-bottom: 3px;
    padding: 2px 6px;
    font-size: 12px;
    border: 1px solid #39b2a8;
    float: left;
    max-width: 80px;
    display: flex;
    .editBabel {
      color: #39b2a8;
      max-width: 60px;
      margin-right: 2px;
    }
  }
  .AddBtn {
    position: absolute;
    right: 6px;
    top: 6px;
    color: #1989fa;
  }
}
.businessTagWrap{
  display: flex;
  justify-content: space-between;
  height: auto;
}
.color000000 {
  color: #000000;
  margin-left: 4px;
}
/deep/ .el-form-item__content {
  line-height: 100%;
  margin-bottom: 0;
}
/deep/ .el-form-item {
  margin-bottom: 0;
}
.vide0Hint {
  font-size: 12px;
  color: #909399;
}
.vidoeBox {
  .upload {
    margin-right: 10px;
    .el-icon-plus {
      width: 288px;
      height: 163px;
      font-size: 50px;
      text-align: center;
      line-height: 163px;
      border: 1px dashed #ccc;
      color: #999;
      cursor: pointer;
    }
  }
  .item {
    position: relative;
    margin-right: 10px;
    .successImg {
      position: absolute;
      right: 0;
      top: 0;
    }
    .delVideos {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      background: #ffffff;
      text-align: center;
      line-height: 30px;
      z-index: 10;
      opacity: 0;
      transition: 0.5s;
      cursor: pointer;
      font-size: 20px;
    }
    &:hover {
      .delVideos {
        opacity: 1;
      }
    }
  }
}
.btn {
  margin: 24px 0 36px;
}
.placeholderHint {
  color: #909399;
}
</style>
